import styled, { css } from "styled-components";

import { TokenIcon as TokenIconUnstyled } from "../token-icon";

interface RectangleProps {
  $isRectangle: boolean;
}
interface WrapperProps extends RectangleProps {
  $tokenBackgroundColor?: string;
}

const iconSize = css`
  min-width: 48px;
  width: 48px;
  height: 48px;
`;

export const Wrapper = styled.div<WrapperProps>`
  position: relative;
  background: ${({ $tokenBackgroundColor }) =>
    $tokenBackgroundColor || "var(--fill-secondary)"};
  z-index: 1;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: ${({ $isRectangle }) =>
    `var(--rounding-radius-${$isRectangle ? "xs" : "xl"})`};
  ${iconSize};
`;

export const SubToken = styled.div<RectangleProps>`
  height: 16px;
  width: 16px;
  background: var(--fill-secondary);
  position: absolute;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  backdrop-filter: blur(12px);
  bottom: ${({ $isRectangle }) => ($isRectangle ? "-4px" : 0)};
  right: ${({ $isRectangle }) => ($isRectangle ? "-4px" : 0)};
  svg {
    width: 12px;
    height: 12px;
  }
`;

export const ImageWrapper = styled.img<WrapperProps>`
  background: ${({ $tokenBackgroundColor }) =>
    $tokenBackgroundColor || "var(--fill-secondary)"};
  ${iconSize};
  border-radius: ${({ $isRectangle }) =>
    `var(--rounding-radius-${$isRectangle ? "xs" : "xl"})`};
`;

export const TokenIcon = styled(TokenIconUnstyled)`
  &.light {
    path {
      fill: var(--icon-on-dark);
    }
  }
`;
