import BigNumber from "bignumber.js";
import React from "react";
import styled from "styled-components";

import type { Candle } from "@/types/common";

interface SimplePriceSVGGraphProps {
  className?: string;
  candles?: Candle[];
  width: number;
  height: number;
  color?: string;
}

const Wrapper = styled.svg<Pick<SimplePriceSVGGraphProps, "width" | "height">>`
  width: ${({ width }) => width}px;
  height: ${({ height }) => height}px;

  path {
    stroke-width: 2px;
  }
`;

export const SimplePriceSVGGraph: React.FC<SimplePriceSVGGraphProps> = ({
  className,
  candles,
  width,
  height,
  color = "black",
}) => {
  const { pathData, circles } = React.useMemo(() => {
    let minPrice: BigNumber | undefined;
    let maxPrice: BigNumber | undefined;

    for (const candle of candles ?? []) {
      if (!minPrice) {
        minPrice = candle.close;
      } else {
        minPrice = BigNumber.min(minPrice, candle.close);
      }

      if (!maxPrice) {
        maxPrice = candle.close;
      } else {
        maxPrice = BigNumber.max(maxPrice, candle.high);
      }
    }

    const diff = maxPrice?.minus(minPrice ?? 0);
    minPrice = minPrice?.minus(diff?.times(0.1) ?? 0);
    maxPrice = maxPrice?.plus(diff?.times(0.1) ?? 0);
    const pathParts: string[] = [];
    const circles: { radius: number; x: number; y: number }[] = [];
    const length = candles?.length ?? 0;

    for (let i = 0; i < length ?? 0; i++) {
      const price = candles?.[i]?.close!;
      const x = (i / (length - 1)) * width;
      const y =
        height -
        price
          .minus(minPrice!)
          .div(maxPrice!.minus(minPrice!))
          .times(height)
          .toNumber();

      // Nan check
      if (!Number.isFinite(y) || !Number.isFinite(x)) {
        console.warn("Nan check failed", { x, y });
        continue;
      }

      pathParts.push(`${i === 0 ? "M" : "L"} ${x},${y}`);
    }

    const pathData = pathParts.join(" ");

    return { pathData, circles };
  }, [candles, width, height]);

  return (
    <Wrapper width={width} height={height} className={className}>
      <path d={pathData} stroke={color} fill="none" />
      {circles.map(({ radius, x, y }) => (
        <circle cx={x} cy={y} r={radius} fill={color} key={`${x}:${y}`} />
      ))}
    </Wrapper>
  );
};
