import { FC } from "react";

import { TokenIcon } from "../token-icon";
import {
  getIsLightToken,
  getSubToken,
  getTokenBrandColor,
  getTokenPng,
} from "../token-image-params";
import * as S from "./styled";

export const TokenImage: FC<{
  token?: string;
  rectangle?: boolean;
  className?: string;
  onClick?: () => void;
}> = ({ token, className, rectangle, onClick }) => {
  const tokenBackgroundColor = token && getTokenBrandColor(token);
  if (!token) {
    return null;
  }
  const tokenPng = getTokenPng(token);
  const isRectangle = Boolean(rectangle);

  if (tokenPng) {
    return (
      <S.ImageWrapper
        className={className}
        src={tokenPng}
        $isRectangle={isRectangle}
        $tokenBackgroundColor={tokenBackgroundColor}
      />
    );
  }

  const isLightToken = getIsLightToken(token);
  const subToken = getSubToken(token);

  return (
    <S.Wrapper
      className={className}
      $isRectangle={isRectangle}
      $tokenBackgroundColor={tokenBackgroundColor}
      onClick={onClick}
    >
      <S.TokenIcon token={token} className={isLightToken ? "light" : ""} />
      {subToken && (
        <S.SubToken $isRectangle={isRectangle}>
          <TokenIcon token={subToken} />
        </S.SubToken>
      )}
    </S.Wrapper>
  );
};
