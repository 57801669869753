import { AvailableTokens } from "./token-icons";

const TOKEN_LARGE_IMAGES: Record<AvailableTokens, string> = {
  ETH: "/images/large-token/eth.png",
  WETH: "/images/large-token/eth.png",
  USDC: "/images/large-token/usdc.png",
  ezETH: "/images/large-token/ezeth.png",
  weETH: "/images/large-token/weeth.png",
  eUSD: "/images/large-token/eusd.png",
  "USDC.e": "/images/large-token/usdc.png",
  ARB: "/images/large-token/arbitrum.png",
  WBTC: "/images/large-token/wbtc.png",
  GMX: "/images/large-token/gmx.png",
  PENDLE: "/images/large-token/pendle.png",
  RDNT: "/images/large-token/rdnt.png",
  USDT: "/images/large-token/usdt.png",
  KNOX: "/images/large-token/knox.png",
  "ETH+": "/images/large-token/eth+.png",
  USDe: "/images/large-token/usde.png",
  sUSDe: "/images/large-token/susde.png",
  USDB: "/images/large-token/usdb.png",
  MUSD: "/images/large-token/musd.png",
  pzETH: "/images/large-token/pzeth.png",
  uniETH: "/images/large-token/unieth.png",
  amphrETH: "/images/large-token/amphreth.png",
  "PT-USDe-29AUG2024": "/images/large-token/usde.png",
  "PT-ezETH-26SEP2024": "/images/large-token/ezeth.png",
  "PT-weETH-26SEP2024": "/images/large-token/weeth.png",
  "PT-weETH-26DEC2024": "/images/large-token/weeth.png",
  "PT-weETH-27JUN2024": "/images/large-token/weeth.png",
  "PT-USDe-25JUL2024": "/images/large-token/usde.png",
  "PT-ezETH-26DEC2024": "/images/large-token/ezeth.png",
  BLAST: "/images/large-token/blast.png",
  "PT-uniETH-26SEP2024": "/images/large-token/unieth.png",
  "PT-amphrETH-26SEP2024": "/images/large-token/amphreth.png",
  "PT-pzETH-26SEP2024": "/images/large-token/pzeth.png",
  "PT-rsETH-26SEP2024": "/images/large-token/rseth.png",
  rsETH: "/images/large-token/rseth.png",
};

export const getTokenLargeImagePath = (token: string) => {
  return TOKEN_LARGE_IMAGES[token as AvailableTokens];
};
