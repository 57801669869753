import BigNumber from "bignumber.js";

import { TEN } from "@/constants/math";
import type { DateISOString } from "@/types/core";

/**
 * @description used for formatting amount inputs
 * @param {string} s - string to transform
 * @param {{int: number; fra: number}} limits - limit integer and fraction number amount
 */
export const transformToAmount = (
  s: string,
  limits?: {
    int: number;
    fra: number;
  },
) => {
  let result = s.replace(/,/g, ".").replace(/[^0-9\.]/g, "");
  const firstDotId = result.indexOf(".");

  if (firstDotId > -1) {
    let int = result.slice(0, firstDotId);
    let fra = result.slice(firstDotId + 1).replace(/\./g, "");

    if (limits) {
      int = int.slice(0, limits.int);
      fra = fra.slice(0, limits.fra);
    }

    result = `${int}.${fra}`;
  } else if (limits) {
    result = result.slice(0, limits.int);
  }

  if (result.length > 0 && result[0] === ".") {
    return result.split(".").slice(0, 2).join(".");
  } else if (result.length > 1 && result[0] === "0" && result[1] !== ".") {
    return result[0];
  } else {
    return result;
  }
};

/**
 * @description used for formatting substrate address inputs
 * @param {string} s - string to transform
 */
export const transformToBase58 = (s: string) =>
  s.replace(/[^1-9A-HJ-NP-Za-km-z]/g, "");

/**
 * @description used for formatting evm & moonbeam address inputs
 * @param {string} s - string to transform
 */
export const transformToHex = (s: string) => {
  if (!s || s === "0" || s === "0x") {
    return s;
  }

  const hex = s.slice(2);
  return "0x" + hex.replace(/[^0-9a-fA-F]/g, "");
};

const ROUNDING_MODE = BigNumber.ROUND_DOWN;
const DECIMAL_PLACES = 4;

export const FORMAT: BigNumber.Format = {
  prefix: "",
  decimalSeparator: ".",
  groupSeparator: ",",
  groupSize: 3,
  secondaryGroupSize: 0,
  fractionGroupSeparator: " ",
  fractionGroupSize: 0,
  suffix: "",
};

export const FORMAT_NO_GROUP_SEPARATOR: BigNumber.Format = {
  prefix: "",
  decimalSeparator: ".",
  groupSeparator: "",
  groupSize: 3,
  secondaryGroupSize: 0,
  fractionGroupSeparator: " ",
  fractionGroupSize: 0,
  suffix: "",
};

export const getZeroDecimalCountFromString = (value: string) => {
  const decimals = value.split(".")[1];

  if (!decimals) return 0;

  const i = decimals.split("").findIndex((el) => el !== "0");

  return i > -1 ? i : 0;
};

const getZeroDecimalCount = (bn: BigNumber) => {
  if (bn.gte(1) || bn.eq(0)) return 0;

  return getZeroDecimalCountFromString(bn.toString());
};

const COMPACT: [number, string][] = [
  [3, "K"],
  [6, "M"],
  [9, "B"],
  [12, "T"],
];

const SMALL_REPLACE_TO = "0...0";

export const formatBigNumber = (
  _bn: BigNumber,
  opts?: {
    compact?: boolean;
    dp?: number;
    rm?: BigNumber.RoundingMode;
    fmt?: BigNumber.Format;
    significantDecimals?: number;
  },
) => {
  let bn = _bn;
  let postfix = "";
  let replace = "";

  if (opts?.compact) {
    const digits = Math.floor(Math.log10(bn.toNumber()));
    let current = -1;

    if (digits < 0) {
      const zeroCount = getZeroDecimalCount(bn);

      const replaceStr = Array.from({ length: zeroCount })
        .map(() => "0")
        .join("");

      if (replaceStr.length >= SMALL_REPLACE_TO.length) {
        replace = replaceStr;
      }
    } else {
      for (let i = 0; i < COMPACT.length; ++i) {
        const [d] = COMPACT[i] as [number, string];

        if (d > digits) {
          break;
        }

        current = i;
      }
    }

    if (current >= 0) {
      const [exp, _postfix] = COMPACT[current] as [number, string];
      postfix = _postfix;
      bn = bn.div(TEN.pow(exp));
    }
  }

  const full =
    bn
      .dp(
        postfix
          ? 2
          : opts?.dp ??
              (opts?.significantDecimals ?? DECIMAL_PLACES) +
                getZeroDecimalCount(bn),
        opts?.rm ?? ROUNDING_MODE,
      )
      .toFormat(opts?.fmt ?? FORMAT) + postfix;

  return replace ? full.replace(replace, SMALL_REPLACE_TO) : full;
};

export const formatNum = (
  num: string | number | BigNumber | undefined | null,
  opts?: {
    dp?: number;
    rm?: BigNumber.RoundingMode;
    fmt?: BigNumber.Format;
    emptyVal?: string;
    prefix?: string;
    postfix?: string;
    significantDecimals?: number;
    compact?: boolean;
  },
) => {
  const isBn = BigNumber.isBigNumber(num);

  if (typeof num !== "number" && typeof num !== "string" && !isBn) {
    return opts?.emptyVal ?? "-";
  }

  const bn = isBn ? num : new BigNumber(num);

  if (bn.isNaN() || !bn.isFinite()) return opts?.emptyVal ?? "-";

  return `${opts?.prefix ?? ""}${formatBigNumber(bn, opts)}${
    opts?.postfix ?? ""
  }`;
};

const LOCALE = "en-US";

export function formatDate(timestamp: number): string {
  const date = new Date(timestamp);

  return `${date.getDate()} ${date.toLocaleString(LOCALE, {
    month: "short",
  })} ${date.getFullYear()}; ${date.toLocaleTimeString(LOCALE, {
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
  })}`;
}

export function formatDateRange(a: DateISOString, b: DateISOString): string {
  const [from, to] = [a, b].map((str) => new Date(str)) as unknown as [
    Date,
    Date,
  ];

  if (to < from) {
    throw new Error("Invalid date range");
  }

  return to.getMonth() === from.getMonth()
    ? to.getDate() === from.getDate()
      ? `${from.getDate()} ${from.toLocaleString(LOCALE, {
          month: "short",
        })} ′${from.getFullYear().toString().substr(-2)}`
      : `${from.getDate()}–${to.getDate()} ${from.toLocaleString(LOCALE, {
          month: "short",
        })} ′${from.getFullYear().toString().substr(-2)}`
    : `${from.getDate()} ${from.toLocaleString(LOCALE, {
        month: "short",
      })}–${to.getDate()} ${to.toLocaleString(LOCALE, {
        month: "short",
      })} ′${from.getFullYear().toString().substr(-2)}`;
}

export const formatTvl = (x: number | undefined) =>
  x
    ? new Intl.NumberFormat("en-US", {
        notation: "compact",
        maximumFractionDigits: 2,
      }).format(x)
    : "-";
