import type { Frame } from "@/hooks/candles";
import type { ChartPeriod } from "@/types/common";

export const FRAME_TO_SECONDS: Record<Frame, number> = {
  OneMinute: 60,
  ThreeMinute: 180,
  FiveMinute: 300,
  FifteenMinute: 900,
  ThirtyMinute: 1800,
  OneHour: 3600,
  FourHour: 14400,
  EightHour: 28800,
  OneDay: 86400,
  OneWeek: 604800,
  OneMonth: 2592000,
};

export const PERIOD_TO_FRAME: Record<ChartPeriod, Frame> = {
  H: "OneMinute",
  "4H": "FiveMinute",
  D: "FifteenMinute",
  W: "OneHour",
  M: "FourHour",
  "3M": "OneDay",
};

export const PERIOD_TO_SECONDS: Record<ChartPeriod, number> = {
  H: 3600,
  "4H": 14400,
  D: 86400,
  W: 604800,
  M: 2592000,
  "3M": 7776000,
};
