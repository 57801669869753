import { AvailableTokens } from "./token-icons";

const TOKEN_BRAND_COLORS: Record<AvailableTokens, string> = {
  KNOX: "#CCD7C0",
  "ETH+": "#83C7FF",
  eUSD: "#ffffff",
  weETH:
    "linear-gradient(13.99deg, #29BCFA 11.17%, #6464E4 42.34%, #B45AFA 83.66%)",
  ezETH: "#000000",
  USDe: "#000000",
  sUSDe: "#ffffff",
  USDT: "#26A17B",
  USDB: "#FCFC03",
  MUSD: "linear-gradient(270deg, #68FFFF 5.56%, #68FF9D 105.56%)",
  ETH: "#627EEA",
  WETH: "#627EEA",
  ARB: "#213147",
  USDC: "#2775CA",
  "USDC.e": "#2775CA",
  GMX: "#FFFFFF",
  WBTC: "#F7931A",
  PENDLE: "#EFF1FC",
  RDNT: "linear-gradient(180deg, #00FFAA 0%, #5E02FA 100%)",
  BLAST: "#FCFC03",
  "PT-weETH-27JUN2024":
    "linear-gradient(13.99deg, #29BCFA 11.17%, #6464E4 42.34%, #B45AFA 83.66%)",
  "PT-weETH-26DEC2024":
    "linear-gradient(13.99deg, #29BCFA 11.17%, #6464E4 42.34%, #B45AFA 83.66%)",
  "PT-USDe-25JUL2024": "#000000",
  "PT-USDe-29AUG2024": "#000000",
  "PT-weETH-26SEP2024":
    "linear-gradient(13.99deg, #29BCFA 11.17%, #6464E4 42.34%, #B45AFA 83.66%)",
  "PT-ezETH-26SEP2024": "#000000",
  "PT-ezETH-26DEC2024": "#000000",
  "PT-amphrETH-26SEP2024": "#E41A1A",
  "PT-pzETH-26SEP2024": "#000000",
  "PT-uniETH-26SEP2024": "#ffffff",
  pzETH: "#000000",
  uniETH: "#ffffff",
  amphrETH: "#E41A1A",
  "PT-rsETH-26SEP2024": "#075A5A",
  rsETH: "#075A5A",
};

export const getTokenBrandColor = (token?: string) =>
  TOKEN_BRAND_COLORS[token as AvailableTokens];

// Unique token image
const TOKEN_PNG: Partial<Record<AvailableTokens, string>> = {
  ezETH: "/images/token-image/ezeth.png",
  "PT-ezETH-26SEP2024": "/images/token-image/ezeth.png",
  "PT-ezETH-26DEC2024": "/images/token-image/ezeth.png",
  ARB: "/images/token-image/arb.png",
  GMX: "/images/token-image/gmx.png",
  "PT-pzETH-26SEP2024": "/images/token-image/pzeth.png",
  "PT-uniETH-26SEP2024": "/images/token-image/unieth.png",
  pzETH: "/images/token-image/pzeth.png",
  uniETH: "/images/token-image/unieth.png",
  "PT-rsETH-26SEP2024": "/images/token-image/rseth.png",
  rsETH: "/images/token-image/rseth.png",
};

export const getTokenPng = (token?: string) => {
  return TOKEN_PNG[token as AvailableTokens];
};

// Unique token image
const SUB_TOKENS: Partial<Record<AvailableTokens, AvailableTokens>> = {
  "USDC.e": "ARB",
};

export const getSubToken = (token?: string) => {
  return SUB_TOKENS[token as AvailableTokens];
};

const LIGHT_TOKENS: AvailableTokens[] = [
  "ETH",
  "WETH",
  "weETH",
  "USDe",
  "USDT",
  "ETH",
  "USDC",
  "USDC.e",
  "WBTC",
  "RDNT",
  "PT-weETH-27JUN2024",
  "PT-weETH-26DEC2024",
  "PT-USDe-25JUL2024",
  "PT-USDe-29AUG2024",
  "PT-weETH-26SEP2024",
  "PT-amphrETH-26SEP2024",
  "amphrETH",
];

export const getIsLightToken = (token: string) =>
  LIGHT_TOKENS.includes(token as AvailableTokens);

// for api/image
const SHARE_TOKEN_PATH: Record<AvailableTokens, string | undefined> = {
  KNOX: `/images/token/knox.svg`,
  "ETH+": "/images/token/eth+.svg",
  eUSD: "/images/token/susde.svg",
  weETH: "/images/token/weeth.svg",
  ezETH: "/images/token/ezeth.svg",
  USDe: "/images/token/usde.svg",
  sUSDe: "/images/token/susde.svg",
  USDT: "/images/token/usdt.svg",
  USDB: "/images/token/usdb.svg",
  MUSD: "/images/token/musd.svg",
  ETH: "/images/token/eth.svg",
  WETH: "/images/token/weth.svg",
  ARB: "/images/token/arb.svg",
  USDC: "/images/token/usdc.svg",
  "USDC.e": "/images/token/usdc.svg",
  GMX: "/images/token/gmx.svg",
  WBTC: "/images/token/wbtc.svg",
  PENDLE: "/images/token/pendle.svg",
  RDNT: "/images/token/rdnt.svg",
  BLAST: "/images/token/blast.svg",
  pzETH: "/images/token/pzeth.svg",
  uniETH: "/images/token/unieth.svg",
  amphrETH: "/images/token/eth.svg",
  "PT-weETH-27JUN2024": "/images/token/weeth.svg",
  "PT-weETH-26DEC2024": "/images/token/weeth.svg",
  "PT-USDe-25JUL2024": "/images/token/usde.svg",
  "PT-USDe-29AUG2024": "/images/token/usde.svg",
  "PT-weETH-26SEP2024": "/images/token/weeth.svg",
  "PT-ezETH-26SEP2024": "/images/token/ezeth.svg",
  "PT-ezETH-26DEC2024": "/images/token/ezeth.svg",
  "PT-amphrETH-26SEP2024": "/images/token/eth.svg",
  "PT-pzETH-26SEP2024": "/images/token/pzeth.svg",
  "PT-uniETH-26SEP2024": "/images/token/unieth.svg",
  "PT-rsETH-26SEP2024": "/images/token/rseth.svg",
  rsETH: "/images/token/rseth.svg",
} as const;

export const getShareTokenPath = (token?: string) => {
  return SHARE_TOKEN_PATH[token as AvailableTokens];
};
